import ServiceDeskClient from "../../client";
import CognitoService from "../../../cognito";

import { GET_MY_CONVERSATIONS, GET_CONVERSATION } from "./graphql";
import { IConversation } from "./types";

export const getMyConversations = async (mailboxId: string) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MY_CONVERSATIONS,
          variables: {
            input: { mailboxId },
          },
        },
      })
    )?.data;

    return {
      data: data.data.getMyConversations.data as IConversation[],
      success: true,
    };
  } catch (err) {
    console.error("getMyConversations", err);
    return {
      success: false,
    };
  }
};

export const getConversation = async (
  id: string,
  mailboxId: string,
  tenantId: string
) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_CONVERSATION,
          variables: {
            input: { id, mailboxId, tenantId },
          },
        },
      })
    )?.data;

    return {
      data: data.data.getConversation,
      success: true,
    };
  } catch (err) {
    console.error("getConversation", err);
    return {
      success: false,
    };
  }
};

export default {
  getMyConversations,
  getConversation,
};
