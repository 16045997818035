import { COURSE_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_COURSES = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query getBuildCourses($input: GetBuildCoursesInput!) {
    getBuildCourses(input: $input) {
      data {
        ...CourseFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query getBuildCourse($input: GetBuildCourseInput!) {
    getBuildCourse(input: $input) {
      ...CourseFields
    }
  }
`;
