export const STUDENT_EXAM_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment StudentExamFields on StudentExam {
    id
    partnerId
    studentId
    examId
    name
    status
    courseId
    papersPassed
    papersPartialPassed
    totalPapers
  }
`;
export const CREATE_STUDENT_EXAM = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  mutation CreateStudentExam($input: CreateStudentExamInput!) {
    createStudentExam(input: $input) {
      ...StudentExamFields
    }
  }
`;
export const GET_STUDENT_EXAM = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  query GetStudentExam($input: GetStudentExamInput!) {
    getStudentExam(input: $input) {
      ...StudentExamFields
    }
  }
`;
export const GET_STUDENT_EXAMS_BY_GSI3 = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  query GetStudentExamsByGSI3($input: GetStudentExamsInput!) {
    getStudentExamsByGSI3(input: $input) {
      data {
        ...StudentExamFields
      }
      nextToken
    }
  }
`;
export const GET_STUDENT_EXAM_WITH = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  query GetStudentExamWith($input: GetStudentExamInput!) {
    getStudentExamWith(input: $input) {
      data {
        ...StudentExamFields
      }
      nextToken
    }
  }
`;
export const UPDATE_STUDENT_EXAM = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  mutation UpdateStudentExam($input: UpdateStudentExamInput!) {
    updateStudentExam(input: $input) {
      ...StudentExamFields
    }
  }
`;
export const DELETE_STUDENT_EXAM = /* GraphQL */ `
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  mutation DeleteStudentExam($input: DeleteStudentExamInput!) {
    deleteStudentExam(input: $input) {
      ...StudentExamFields
    }
  }
`;
