import ServiceDeskClient from "../../client";
import CognitoService from "../../../cognito";

import {
  GET_MAILBOXES,
  GET_MAILBOX,
  CREATE_MAILBOX,
  UPDATE_MAILBOX,
  REMOVE_MAILBOX,
  GET_MAILBOX_BY_PARTNER_ID,
} from "./graphql";
import { SERVICE_DESK_API_KEY } from "../../../../config-global";

export const getMailboxes = async (tenantId: string) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MAILBOXES,
          variables: {
            input: { tenantId },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getMailboxes,
      success: true,
    };
  } catch (err) {
    console.error("getMailboxes", err);
    return {
      success: false,
    };
  }
};

export const getMailbox = async (id: string, tenantId: string) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MAILBOX,
          variables: {
            input: { id, tenantId },
          },
        },
      })
    )?.data;

    return {
      data: data.data.getMailbox,
      success: true,
    };
  } catch (err) {
    console.error("getMailbox", err);
    return {
      success: false,
    };
  }
};

export const getMailboxByPartnerId = async (id: string) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MAILBOX_BY_PARTNER_ID,
          variables: {
            input: { id },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getMailboxByPartnerId,
      success: true,
    };
  } catch (err) {
    console.error("getMailboxByPartnerId", err);
    return {
      success: false,
    };
  }
};

export const createMailboxWithForwardToEmail = async (
  name: string,
  tenantId: string,
  email: string
) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: CREATE_MAILBOX,
          variables: {
            input: { name, tenantId, email },
          },
        },
      })
    )?.data;

    return {
      data: data.data.createMailboxWithForwardToEmail,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const updateMailbox = async (
  id: string,
  name: string,
  tenantId: string
) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: UPDATE_MAILBOX,
          variables: {
            input: { id, name, tenantId },
          },
        },
      })
    )?.data;

    return {
      data: data.data.updateMailbox,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const removeMailbox = async (id: string, tenantId: string) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: REMOVE_MAILBOX,
          variables: {
            input: { id, tenantId },
          },
        },
      })
    )?.data;

    return {
      data: data.data.deleteMailbox,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export default {
  getMailboxes,
  getMailbox,
  createMailboxWithForwardToEmail,
  updateMailbox,
  removeMailbox,
  getMailboxByPartnerId,
};
