import React, { useEffect, useState } from 'react'
import { AuthProvider } from '../context/auth'
import { PartnerProvider } from '../context/partner';
import { CourseProvider } from '../context/course';
import DevTools from '../components/devtools';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import AppLayout from './app/Layout';
import { MessageProvider } from '../context/message';

import './i18n';
import { PartnerGuard } from '../context/partner/guard';

type Props = {
  children: React.ReactNode;
  wrapperProps?: any;
};

export default function GlobalLayout({ children, wrapperProps }: Props) {
  const isDevMode = process.env.GATSBY_DEV_TOOLS_ENBALED === "true";
  return (
    <PartnerProvider>
      <PartnerGuard>
        <MessageProvider wrapperProps={wrapperProps}>
          <AuthProvider>
            <CourseProvider wrapperProps={wrapperProps}>
              <AppLayout>
                {children}
                {isDevMode && <DevTools />}
              </AppLayout>
            </CourseProvider>
          </AuthProvider>
        </MessageProvider>
      </PartnerGuard>
    </PartnerProvider>
  )
}
