export const CONVERSATION_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ConversationFields on Conversation {
    id
    mailboxId
    tenantId
    subject
    contact {
      id
      name
      email
      firstName
      lastName
    }
    co
  }
`;

export const GET_MY_CONVERSATIONS = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  query GetMyConversations($input: GetMyConversationsInput!) {
    getMyConversations(input: $input) {
      data {
        ...ConversationFields
      }
      nextToken
    }
  }
`;

export const GET_CONVERSATION = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  query GetConversation($input: GetConversationInput!) {
    getConversation(input: $input) {
      ...ConversationFields
    }
  }
`;
