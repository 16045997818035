exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-extend-[id]-tsx": () => import("./../../../src/pages/extend/[id].tsx" /* webpackChunkName: "component---src-pages-extend-[id]-tsx" */),
  "component---src-pages-glossary-[id]-tsx": () => import("./../../../src/pages/glossary/[id].tsx" /* webpackChunkName: "component---src-pages-glossary-[id]-tsx" */),
  "component---src-pages-help-[id]-index-tsx": () => import("./../../../src/pages/help/[id]/index.tsx" /* webpackChunkName: "component---src-pages-help-[id]-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-my-messages-tsx": () => import("./../../../src/pages/my-messages.tsx" /* webpackChunkName: "component---src-pages-my-messages-tsx" */),
  "component---src-pages-stripe-return-tsx": () => import("./../../../src/pages/stripe/return.tsx" /* webpackChunkName: "component---src-pages-stripe-return-tsx" */),
  "component---src-templates-course-progress-tsx": () => import("./../../../src/templates/courseProgress.tsx" /* webpackChunkName: "component---src-templates-course-progress-tsx" */),
  "component---src-templates-course-resources-tsx": () => import("./../../../src/templates/courseResources.tsx" /* webpackChunkName: "component---src-templates-course-resources-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-course-videos-tsx": () => import("./../../../src/templates/courseVideos.tsx" /* webpackChunkName: "component---src-templates-course-videos-tsx" */),
  "component---src-templates-exam-paper-index-tsx": () => import("./../../../src/templates/examPaper/index.tsx" /* webpackChunkName: "component---src-templates-exam-paper-index-tsx" */),
  "component---src-templates-exam-tsx": () => import("./../../../src/templates/exam.tsx" /* webpackChunkName: "component---src-templates-exam-tsx" */),
  "component---src-templates-module-summary-tsx": () => import("./../../../src/templates/moduleSummary.tsx" /* webpackChunkName: "component---src-templates-module-summary-tsx" */),
  "component---src-templates-module-tsx": () => import("./../../../src/templates/module.tsx" /* webpackChunkName: "component---src-templates-module-tsx" */),
  "component---src-templates-topics-summary-tsx": () => import("./../../../src/templates/topics/summary.tsx" /* webpackChunkName: "component---src-templates-topics-summary-tsx" */),
  "component---src-templates-topics-template-tsx": () => import("./../../../src/templates/topics/template.tsx" /* webpackChunkName: "component---src-templates-topics-template-tsx" */),
  "component---src-templates-topics-template-with-subtopics-tsx": () => import("./../../../src/templates/topics/template-with-subtopics.tsx" /* webpackChunkName: "component---src-templates-topics-template-with-subtopics-tsx" */)
}

